import ru from './ru.json';
import ua from './ua.json';
import en from './en.json';
import kz from './kz.json';
import nl from './nl.json';
import de from './de.json';

const locales = {
  ru,
  ua,
  en,
  kz,
  nl,
  de
};

export default locales;
